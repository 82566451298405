import React from 'react'
import { Link } from 'react-router-dom'

const CtaHeader = () => {
  return (
    <div className="w-full flex items-center justify-center text-white cta mt-28">
        <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">                    
            
        </div>
    </div>
  )
}

export default CtaHeader