import React from 'react'

const Dropdown = ({ submenus , dropdown}) => {
  return (
    
    
    <div>
    {dropdown ? (
      <ul className={`dropdown absolute py-4 bg-gray-100`} >
      {submenus.map((submenu, index) => (
        <li key={index} className="menu-items py-2 px-4" >
          <a href={submenu.url} className='uppercase font-bold text-sm'>{submenu.title}</a>
        </li>
      ))}
    </ul>
    ):(<h1></h1>)}
    </div>
  )
}

export default Dropdown