import React from 'react'
import NavBar from '../components/Navbar/NavBar'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import {OffShoreService,FutureTechSolution, MarineElectrical, MarineService} from '../components/ClientImages/ClientImages';

const ElectricalAutomationServices = () => {
  return (
    <>
    <div>
        <NavBar />
    </div>
    <div className="m-auto overflow-hidden  mt-8 lg:mt-28 p-2 md:p-12 h-96 cta-electrical-service bg-cover" data-aos="zoom-in">
      <div id='hero' className="flex flex-col lg:flex-row  justify-between text-center lg:text-left">
          <div className="lg:w-full flex flex-col justify-center  h-96" data-aos="zoom-in" data-aos-delay="100">
              <h4 className="mb-5 md:text-6xl text-4xl font-bold text-white mt-8 my-20 w-1/2">
              {/* We build digital solutions to help businesses scale */}
              Future Technology Solutions
              </h4>
          </div>
      </div>
    </div>
    <div>
    <section data-aos="zoom-in-down">
      {/* <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>
          
          <div className='flex justify-center'>
              <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">One carrier for all your solutions</h2>
      </div> */}

      <div className="px-12 py-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
              <h2 className='text-5xl'>Other Services</h2>
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                  <div className="m-2 text-justify text-sm">
                      <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={FutureTechSolution} />
                      <h2 className="font-semibold my-4 text-2xl text-center">Future Technology Solutions</h2>
                      {/* <p className="text-md font-medium">
                          We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                      </p> */}                      
                  </div>
              </div>
{/* 
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                  <div className="m-2 text-justify text-sm">
                  <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={MarineElectrical} />
                      <h2 className="font-semibold my-4 text-2xl text-center">Marine Electrical, Automations & Control Services</h2>
                      
                  </div>
              </div> */}

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                  <div className="m-2 text-justify text-sm">
                      <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={MarineService} />
                      <h2 className="font-semibold my-4 text-2xl text-center ">Marine Services</h2>
                      
                  </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                  <div className="m-2 text-justify text-sm">
                  <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={OffShoreService} />
                      <h2 className="font-semibold my-4 text-2xl text-center ">Offshore Services</h2>
                      {/* <p className="text-md font-medium">
                          Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.
                      </p> */}
                  </div>
              </div>                    
          </div>
      </div>
    </section>

    </div>
    <Cta/>
    <Footer/>
    </>
  )
}

export default ElectricalAutomationServices