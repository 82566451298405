import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Torm,BritishMarine, CochinShipyard, HALOffshore, MD, Maersk, NDPL, Scorpio, SevenLands, Ships, ShreeramGroup, TheGreatEasternShippingCompany, TransworldShipManagement, SynergyGroup, ShippingCorporation, HindOffshore, BernhardShulteShipManagement} from './ClientImages/ClientImages';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

const Clients = () => {
    return (
        <div className="mt-24 ">
            <section data-aos="fade-up">
                {/* <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl  uppercase font-bold">Our Clients</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold ">Some of our clients.</h2>
                </div> */}
                <div className="my-1 py-4 mx-8">
                    <div className='w-1/6'>
                        <div class="relative flex py-5 items-center px-16">
                            <span class="text-lg flex-shrink mx-4 text-gray-900">Clients</span>
                            <div class="flex-grow border-t border-gray-400 w-25"></div>
                        </div>
                    </div>
                    <h2 className="mt-4 px-20 text-6xl lg:text-4xl ">One carrier for all your solutions</h2>
                </div>

                {/* <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3">
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                            <img src={Torm} alt="client" />                           
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={BritishMarine} alt="client" />                            
                        </div> 

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={CochinShipyard} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={HALOffshore} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={MD} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={Maersk} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={NDPL} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={Scorpio} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={SevenLands} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={Ships} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={ShreeramGroup} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={TheGreatEasternShippingCompany} alt="client" />                            
                        </div>
                        
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={TransworldShipManagement} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={SynergyGroup} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={ShippingCorporation} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={HindOffshore} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={BernhardShulteShipManagement} alt="client" />                            
                        </div>

                                           
                    </div>
                </div> */}
                <div className='p-20'>
                    <div className='grid sm:grid-cols-8 lg:grid-cols-12 gap-2 mx-8 pt-8'>
                        <div className='col-span-2 border-r-2 border-gray-800 me-4'>
                            <h3 className='text-3xl'>Trusted by 50+ global partners</h3>
                        </div>
                        <div className='col-span-10 ml-8'>
                            <Carousel 
                            responsive={responsive}
                            infinite={true}
                            rtl={false}
                            autoPlaySpeed={3000}
                            autoPlay={true}
                            >
                            <img src={Torm} alt="client" className='w-25'/>
                            <img src={BritishMarine} alt="client" className='w-25'/> 
                            <img src={CochinShipyard} alt="client" className='w-25'/> 
                            <img src={HALOffshore} alt="client" className='w-25'/>
                            <img src={MD} alt="client" className='w-25'/>   
                            <img src={Maersk} alt="client" className='w-25'/>
                            <img src={NDPL} alt="client" className='w-25'/>   
                            <img src={Scorpio} alt="client" className='w-25'/>     
                            <img src={SevenLands} alt="client" className='w-25'/> 
                            <img src={Ships} alt="client" className='w-25'/> 
                            <img src={ShreeramGroup} alt="client" className='w-25'/>
                            <img src={TheGreatEasternShippingCompany} alt="client" className='w-25'/> 
                            <img src={TransworldShipManagement} alt="client" className='w-25'/> 
                            <img src={SynergyGroup} alt="client" className='w-25'/> 
                            <img src={ShippingCorporation} alt="client" className='w-25'/>  
                            <img src={HindOffshore} alt="client" className='w-25'/> 
                            <img src={BernhardShulteShipManagement} alt="client" className='w-25'/> 
                            </Carousel>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

export default Clients;