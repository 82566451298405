import React from 'react'
import NavBar from '../components/Navbar/NavBar'

import {OffShoreService,FutureTechSolution, MarineElectrical, MarineService} from '../components/ClientImages/ClientImages';
import Cta from '../components/Cta';
import Footer from '../components/Footer';

const Services = () => {
  return (
    <>
    <div>
        <NavBar />
    </div>
    <div className="w-full flex items-center justify-center text-white cta-service mt-28 py-16 px-12 h-96"></div>
    <div className="w-full items-center justify-center m-auto overflow-hidden mx-4 mt-16 lg:mt-4 p-2 md:p-12 h-5/6">
        <div className='justify-center'>
            <h1 className='w-full text-xl font-bold'>Services</h1>
        </div>
        <div className='justify-center mt-2'>
            <h1 className='w-full text-5xl font-bold'>Integri Services</h1>
        </div>
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down"> 
                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={FutureTechSolution} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Future Technology Solutions</h2>
                                    {/* <p className="text-md font-medium">
                                        We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                    </p> */}
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={MarineElectrical} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Marine Electrical, Automations & Control Services</h2>
                                    {/* <p className="text-md font-medium">
                                        We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.
                                    </p> */}
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={MarineService} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Marine Services</h2>
                                    {/* <p className="text-md font-medium">
                                        We provide domain registration and web hosting services to individuals and organizations to enable them gain visibility in the digital space.
                                    </p> */}
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={OffShoreService} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Offshore Services</h2>
                                    {/* <p className="text-md font-medium">
                                        Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.
                                    </p> */}
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>
        </div>
    </div>
    <Cta/>
    <Footer/>
    </>
  )
}

export default Services