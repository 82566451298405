import React, {useState, useEffect} from 'react'
import NavBar from '../components/Navbar/NavBar'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import { URL } from '../api/apis'
import { Link } from 'react-router-dom'


const CareerList = () => {
    const [firstName, setFirstName] = useState();
    const [errors, setErrors] = useState();
    const [departments, setDepartments] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const clearErrors = () => {
        setErrors([])
    }

    useEffect(()=>{
        const getCareers = async() =>{
            const query = await fetch(URL.careers);
            const res = await query.json();
            console.log(res);
            setIsLoad(false);
            setDepartments(res);
        }
        if(isLoad){
            getCareers();
        }
    },[])


    return (
        <>
        <div>
            <NavBar />
        </div>
        
        <div className='pt-20'>
            <section className='py-20'>            
                <div className='grid sm:grid-cols-10 lg:grid-cols-10 gap-5 mx-32 pt-8'>
                    <span>Integri Career</span>
                    <hr className='align-middle mt-3 text-black'/>
                    <div className='col-span-8 px-16'>
                        {/* <h1 className='text-xl font-bold'>Department Title </h1> */}
                        <div>
                            {departments && departments?.map((d_jobs, index)=>(
                                <div key={index}>
                                    <div className='flex'>
                                        <h1 className='text-2xl font-bold'>{d_jobs?.name}</h1>
                                        <div className="box-border bg-gray-200 px-2 rounded-full mx-2 pt-1 text-sm ">{d_jobs?.jobs.length} Job</div>
                                    </div>
                                    {d_jobs?.jobs.map((job, jindex)=>(
                                        <div key={jindex} className='border rounded p-2 my-4 px-4'>
                                            <div className='flex'>
                                                <Link to={`/career-detail/${job?.id}`}><h1 className='text-xl font-bold'>{job?.job_title}({job?.job_code})</h1></Link>
                                            </div>
                                            <div className='flex pt-2'>
                                                <span className='text-gray-500'>{job?.location}</span>  
                                                <div className='dot dot-xs bg-slate-900 mx-2'></div>
                                                <span className='text-gray-500'>{job?.year_experience}</span>  
                                                <div className='dot dot-xs bg-slate-900 mx-2'></div>
                                                <span className='text-gray-500'>{job?.job_type}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* <div className='grid sm:grid-cols-2 lg:grid-cols-5 gap-5 mx-32 pt-8'>
                    <div className="w-auto bg-white justify-center w-5/6">
                        <h1 className='text-xl'>Job Opening </h1>
                    </div>
                    <div className="w-auto bg-white justify-center w-auto">
                        <h1 className='text-xl'>Department Title </h1>
                    </div>                
                </div>
                <div className='justify-center w-full flex pt-16'>
                    
                </div> */}
                
            </section>
        </div>
        <Cta/>
        <Footer/>
        </>
    )
}

export default CareerList