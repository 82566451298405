import React, {useState, useEffect}  from 'react';
import NavBar from '../components/Navbar/NavBar'
import { URL } from '../api/apis';
import {useParams} from "react-router-dom";
import { Link } from 'react-router-dom';

const CareerDetail = () => {
    const [isLoad, setIsLoad] = useState(true);
    const [job, setJob] = useState();
    const { id } = useParams();

    useEffect(()=>{
        console.log("Id==>"+id);
        const getJob = async() =>{
            const query = await fetch(URL.job, {method:'POST',body:JSON.stringify({id:id}), headers:{'Content-type': 'application/json','Access-Control-Allow-Origin':'*'}});
            const res = await query.json();
            console.log(res);
            setIsLoad(false);
            setJob(res);
        }
        if(isLoad){
            getJob();
        }
    },[]);

    return (
        <>
        <div>
            <NavBar />
        </div>
        {job && (
        <div className='pt-32'>
            <div className='bg-blue-100 py-12'>
                <section className='px-20 '>            
                    <div className='grid sm:grid-cols-10 lg:grid-cols-1 gap-5 mx-32 pt-8'>
                        <span className='text-3xl'>{job?.job_title} ({job?.job_code})</span>
                        <div className='flex'>
                            <span className='text-gray-500'>{job?.location}</span>  
                            <div className='dot dot-xs bg-slate-900 mx-2'></div>
                            <span className='text-gray-500'>{job?.year_experience}</span>  
                            <div className='dot dot-xs bg-slate-900 mx-2'></div>
                            <span className='text-gray-500'>{job?.job_type}</span>
                        </div>
                    </div>
                </section>
            </div>
            <div className='py-12 px-32 mx-20'>
                <Link to={`/apply-job/1/${job?.job_title}`}><button className='text-white bg-blue-900 rounded p-2 px-4 mb-4'>Apply</button></Link>
                <span dangerouslySetInnerHTML={{__html: job?.job_description.replaceAll('<ul>', '<ul class="list-inside list-disc">')}} />
            </div>
        </div>
        )}
        </>
    )
}

export default CareerDetail