import React from 'react'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import NavBar from '../components/Navbar/NavBar'
import {Torm,BritishMarine, CochinShipyard, HALOffshore, MD, Maersk, NDPL, Scorpio, SevenLands, Ships, ShreeramGroup, TheGreatEasternShippingCompany, TransworldShipManagement, SynergyGroup, ShippingCorporation, HindOffshore, BernhardShulteShipManagement} from '../components/ClientImages/ClientImages';
import CtaHeader from '../components/CtaHeader';

const ClientsList = () => {
  return (
    <>
    <div>
        <NavBar />
    </div>
    <CtaHeader/>
    <div className="w-full items-center justify-center m-auto overflow-hidden mx-8 mt-16 lg:mt-24 p-2 md:p-12 h-5/6">
        <div className='justify-center'>
            <h1 className='w-full text-3xl font-bold'>Clients</h1>
        </div>
        <div className="grid grid-cols-4 gap-4 h-screen">
            <img src={Torm} alt="client" className='w-25 px-12 py-12'/>
            <img src={BritishMarine} alt="client" className='w-25 px-12 py-12'/>
            <img src={CochinShipyard} alt="client" className='w-25 px-12 py-12'/> 
            <img src={HALOffshore} alt="client" className='w-25 px-12 py-12'/>
            <img src={MD} alt="client" className='w-25 px-12 py-12'/>   
            <img src={Maersk} alt="client" className='w-25 px-12 py-12'/>
            <img src={NDPL} alt="client" className='w-25 px-12 py-12'/>   
            <img src={Scorpio} alt="client" className='w-25 px-12 py-12'/>     
            <img src={SevenLands} alt="client" className='w-25 px-12 py-12'/> 
            <img src={Ships} alt="client" className='w-25 px-12 py-12'/> 
            <img src={ShreeramGroup} alt="client" className='w-25 px-12 py-12'/>
            <img src={TheGreatEasternShippingCompany} alt="client" className='w-25 px-12 py-12'/> 
            <img src={TransworldShipManagement} alt="client" className='w-25 px-12 py-12'/> 
            <img src={SynergyGroup} alt="client" className='w-25 px-12 py-12'/> 
            <img src={ShippingCorporation} alt="client" className='w-25 px-12 py-12'/>  
            <img src={HindOffshore} alt="client" className='w-25 px-12 py-12'/> 
            <img src={BernhardShulteShipManagement} alt="client" className='w-25 px-12 py-12'/> 
        </div> 
    </div>
    <Cta/>
    <Footer  />
    </>
  )
}

export default ClientsList