import React,{useState,useEffect} from 'react'
import NavBar from '../components/Navbar/NavBar'
import { URL, base_url } from '../api/apis';
import { Link, useParams } from 'react-router-dom';

const Blogs = () => {
    const[blogs, setBlogs] = useState();
    const[blogg, setBlogg] = useState();
    const [isLoad, setIsLoad] = useState(true);
    const { id } = useParams();

    useEffect(()=>{
        const getBlogs = async() =>{
            const query = await fetch(URL.blogs);
            const res = await query.json();
            console.log(res);
            setIsLoad(false);
            setBlogs(res);
            if(id){
                setBlogg(res.filter((obj)=>{
                    return obj.id === id;
                }));
            }else{
                setBlogg(res[0]);            
            }
        }
        // if(isLoad){
            getBlogs();
        // }
    },[]);


    const changeBlogg =(id) =>{
        console.log("id:::"+id);
        let n_blog = blogs.filter((obj)=>{
            return obj.id === id;
        })
        console.log(n_blog[0]);
        setBlogg(n_blog[0]);
    }

    return (
        <>
            <NavBar />
            <div className='py-32 px-28 mx-16'>
                <div className="grid sm:grid-cols-2 lg:grid-cols-10 gap-5">
                    <div className='col-span-6'>
                        <div>
                            <div className='py-2'>
                                <h1 className='text-xl font-bold'>Main Blogs</h1>
                            </div>
                            {blogg && (
                                <>
                                    <img src={base_url+blogg?.image} className='w-auto h-fit' />
                                    <h1 className='text-xl font-bold py-4'>{blogg?.title}</h1>
                                    <hr/>
                                    <span dangerouslySetInnerHTML={{__html: blogg?.description.replaceAll('<ul>', '<ul class="list-inside list-disc">')}} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-span-4'>
                        <h3 className='font-bold py-1 text-lg'>Latest Blogs</h3>
                        <hr/>
                        {blogs && blogs.map((blog, index)=>(
                            <div key={index} className=' w-25 my-2'>
                                <div className='flex'>
                                    <div>
                                        <img src={base_url+blog?.image} className='' style={{height:'100px',width:'190px'}}/>
                                    </div>
                                    <div className='px-4'>
                                        <Link to={`/blogs/${blog.id}`}>
                                            <span className='w-full pt-8 font-bold align-top' onClick={()=>changeBlogg(blog.id)}>{blog?.title}</span>
                                            <span className='w-full text-gray-500 inline-block align-middle'>{blog?.sub_title}</span>
                                            <span className='text-sm text-gray-500'><span>Published On</span> - {blog?.publish_date}</span>
                                        </Link>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs