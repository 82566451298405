import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../images/Logo.jpg'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-32 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid g-7 sm:grid-cols-8 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-2">
                            {/* <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                                <h3 className="font-bold text-4xl mb-4">MLD</h3>
                                <div className='text-md font-medium text-gray-600'>
                                    <h5>Molad e Konsult</h5>
                                    <p>Ilo Awela,</p>
                                    <p>Ota,</p>
                                    <p>Ogun State.</p>
                                </div>                            
                            </div> */}
                            <img src={Logo} alt="Logo" className="w-60"/>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-2 md:col-span-2 lg:col-span-1 ml-7 mx-auto font-bold text-center">
                            <h6 className="text-[#013289] text-xl font-bold mb-4 uppercase hover:tracking-wider transition duration-250 ease-in-out hover:text-amber-600">GERMANY</h6>
                            <span className='text-xs hover:text-amber-600'>Norderstedt, Germany</span>
                            
                            {/* <ul className="text-md">
                            <li className="mb-2">
                                <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                            </li>
                            <li className="mb-2">
                                <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                            </li>                            
                            </ul> */}
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-2 md:col-span-2 lg:col-span-1 mx-auto text-center font-bold ">
                            <h6 className="text-[#013289] text-xl font-bold mb-4 uppercase hover:tracking-wider transition duration-250 ease-in-out hover:text-amber-600">NETHERLANDS</h6>
                            <span className='text-xs hover:text-amber-600'>Rotterdam, Netherlands</span>
                            {/* <ul className="text-md">
                            <li className="mb-2">
                                <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Web Development</Link>
                            </li>
                            <li className="mb-2">
                                <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Mobile App Development</Link>
                            </li>
                            <li className="mb-2">
                                <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Domain and Hosting</Link>
                            </li>
                            <li className="mb-2">
                                <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                            </li>
                            </ul> */}
                        </div>

                        {/* 4th block */}
                        <div className="col-span-2 text-center mx-auto md:col-span-2 lg:col-span-1 font-bold">
                            <h6 className="text-[#013289] text-xl font-bold mb-4 uppercase hover:tracking-wider transition duration-250 ease-in-out hover:text-amber-600"> INDIA </h6>
                            <span className='text-xs hover:text-amber-600'>Navi Mumbai, India</span>
                        </div>

                        <div className="col-span-2 text-center mx-auto lg:col-span-1 font-bold">
                            <h6 className="text-[#013289] text-xl font-bold mb-4 uppercase hover:tracking-wider transition duration-250 ease-in-out hover:text-amber-600">QATAR   </h6>
                            <span className='text-xs hover:text-amber-600'>Doha, Qatar</span>
                        </div>

                        <div className="col-span-2 text-center mx-auto lg:col-span-1 font-bold">
                            <h6 className="text-[#013289] text-xl font-bold mb-4 uppercase hover:tracking-wider transition duration-250 ease-in-out hover:text-amber-600">Dubai </h6>
                            <span className='text-xs hover:text-amber-600'>Dubai/Fujairah, UAE</span>
                        </div>

                        <div className="col-span-2 text-center mx-auto lg:col-span-1 font-bold">
                            <h6 className="text-[#013289] text-xl font-bold mb-4 uppercase hover:tracking-wider transition duration-250 ease-in-out hover:text-amber-600"> Denmark </h6>
                            <span className='text-xs hover:text-amber-600'>Kokkedal, Denmark</span>
                        </div>

                        {/* <div className="col-span-12 md:col-span-12 lg:col-span-12 mx-auto">
                            <div className="text-lg font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg>
                                        </Link>
                                    </li>
                                    <li className="ml-4">
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                        </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>           */}

                    </div>

                    <div className="grid g-7 sm:grid-cols-6 lg:grid-cols-12  gap-3 py-8 md:py-8 border-gray-200 lg:mx-4">
                        {/* <div className="col-span-2 md:col-span-4 lg:col-span-1 ml-7 mx-auto font-bold"></div> */}
                        <div className="col-span-2 md:col-span-2 lg:col-span-2 ml-7 mx-auto font-bold divide-y border-b-2 hover:border-amber-600 border-blue-800 hover:transition hover:duration-150">
                            <Link to={`/integri-services/marine-services`}><h3 className='text-md uppercase pb-2 text-blue-800 hover:text-amber-600'>Marine Services</h3></Link>
                        </div>
                        <div className="col-span-2 md:col-span-2 lg:col-span-4 ml-7 mx-auto font-bold divide-y border-b-2 hover:border-amber-600 border-blue-800">
                            <Link to={`/integri-services/marine-electrical-automations-control-services`}><h3 className='text-md uppercase pb-2 text-blue-800 hover:text-amber-600'>Marine Electrical Automation Services</h3></Link>
                        </div>
                        <div className="col-span-2 md:col-span-2 lg:col-span-2 ml-7 mx-auto font-bold border-b-2 hover:border-amber-600 border-blue-800">
                            <Link to={`/integri-services/offshore-services`}><h3 className='text-md uppercase pb-2 text-blue-800 hover:text-amber-600'>OffShore Services</h3></Link>
                        </div>
                        <div className="col-span-2 md:col-span-2 lg:col-span-3 ml-7 mx-auto font-bold border-b-2 hover:border-amber-600 border-blue-800">
                            <Link to={`/integri-services/future-technology-solutions`}><h3 className='text-md uppercase pb-2 text-blue-800 hover:text-amber-600'>Future Technology Services</h3></Link>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-16 py-16">
                        <div className='flex'>
                            {/* <span className='text-sm px-4 text-gray-600'>Facebook</span>
                            <span className='text-sm px-4 text-gray-600'>Instagram</span> */}
                            <a href="https://www.linkedin.com/company/integri-marine-and-offshore-services-pvt.-ltd./" target='_blank'><span className='text-sm px-4 text-gray-600'>LinkedIn</span></a>
                        </div>
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-blue-500 font-semibold py-1">
                            Copyright &copy; {new Date().getFullYear()}{"  "}
                            <HashLink
                                to="#"
                                className="text-gray-900 hover:text-gray-900"
                            >
                                Integri Marine
                            </HashLink>. All rights reserved.
                            </div>
                        </div>
                        <div className='flex'>
                            <span className='text-sm px-4 text-gray-600'>Privacy Policy</span>
                            <span className='text-sm px-4 text-gray-600'>Terms and Condition</span>
                        </div>
                    </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
