import React, {useState} from 'react'
import NavBar from '../components/Navbar/NavBar'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import {useParams} from "react-router-dom";

const ApplyJob = () => {
    const { id , job_title, job_code } = useParams();
    const [formData, setFormData] = useState({
        name:'',
        email:'',
        contact_no:'',
        message:'',
        resume:undefined,
    });
    const clearErrors="";
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const changeFile = (e) => {

    }

    const validate =() =>{
        
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {

        }
    }

    return (
        <>
            <div>
                <NavBar />
            </div>
            
            <div className='pt-20'>
                <section className='py-20'>            
                    <div className='grid sm:grid-cols-10 lg:grid-cols-10 gap-5 mx-32 pt-8'>
                        <span>Integri Career</span>
                        <hr className='align-middle mt-3 text-black'/>
                    </div>
                    <div className='grid sm:grid-cols-2 lg:grid-cols-2 gap-5 mx-32 pt-8'>
                        <div className="w-auto bg-white justify-center w-5/6">
                            <h1 className='text-3xl text-blue-900'>{job_title}</h1>
                        </div>
                        <div className="w-auto bg-white justify-center">                    
                            {/* <p className='py-4'>Come aboard Integri Marine to start your journey to success. Explore new frontiers to the future in a dynamic and competitive industry with our expert-led team that thrives on challenges, innovation and peer collaboration to make the journey as exciting as the destination itself.</p> */}
                            <form onSubmit={handleSubmit}>
                            <div className='grid sm:grid-col-2 lg:grid-cols-2'>
                                <div className='px-2'>
                                    <label>Full Name</label>
                                    <input 
                                        name="first_name" 
                                        className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Enter Your Name*" 
                                        value={formData.name}
                                        onChange={handleChange}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                                <div className='px-2'>
                                    <label>Email</label>
                                    <input 
                                        name="email" 
                                        className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Enter Your Email" 
                                        value={formData.email}
                                        onChange={handleChange}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                                <div className='px-2 pt-4'>
                                    <label>Phone Number</label>
                                    <input 
                                        name="contact_no" 
                                        className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Enter Phone Number" 
                                        value={formData.contact_no}
                                        onChange={handleChange}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                                <div className='px-2 pt-4'>
                                    <label>Working Experience</label>
                                    <input 
                                        name="working_experience" 
                                        className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Enter Your Working Experience" 
                                        value={formData.contact_no}
                                        onChange={handleChange}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                </div>
                            </div>
                            <div className='grid sm:grid-col-1 lg:grid-cols-1 py-4'>
                                <div className='px-2'>
                                    <label>Message</label>
                                    <input 
                                        name="message" 
                                        className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="write about yourself" 
                                        value={formData.contact_no}
                                        onChange={handleChange}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                            </div>
                            <div className='grid sm:grid-col-1 lg:grid-cols-1 py-4'> 
                                <div className='px-2'>
                                    <label className='py-2'>Upload Resume</label>
                                    <input type='file' className='form-control w-full' name='resume' accept='application/pdf,application/msword'/>
                                </div>
                            </div>   
                            <div className='grid sm:grid-col-1 lg:grid-cols-1 py-4'>    
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                    <div>
                                        <button className='btn btn-primary bg-black text-white p-2 rounded'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </div>                    
                    </div>
                    <div className='justify-center w-full flex pt-16'>
                        
                    </div>
                    
                </section>
            </div>
            <Cta/>
            <Footer/>
        </>
    )
}

export default ApplyJob